<template>
  <div class="px-2 py-5">
    <h1 class="title">Toronto Psoriatic Arthritis Screening Questionnaire</h1>
    <b-message class="is-info">
      La artritis psoriásica es una artropatía inflamatoria asociada con
      psoriasis1 que afecta a entre 6 y 39% de los pacientes con psoriasis, con
      una prevalencia en la población general que va del 0,02 al 0,2%2 . Una
      demora en el diagnóstico y el tratamiento puede generar dano ̃ articular,
      deformidad y secuelas funcionales3
    </b-message>
    <div class="columns">
      <div class="column">
        <img
          class="helper-img"
          src="@/assets/TOPAS/TOPAS_img_1.png"
          srcset=""
        />
        <p><strong>Figura 1:</strong> Erupción en la piel de los codos</p>
      </div>
      <div class="column">
        <img
          class="helper-img"
          src="@/assets/TOPAS/TOPAS_img_2.png"
          srcset=""
        />
        <p><strong>Figura 2:</strong> Piquetes en las uñas</p>
      </div>
      <div class="column">
        <img
          class="helper-img"
          src="@/assets/TOPAS/TOPAS_img_3.png"
          srcset=""
        />
        <p><strong>Figura 3:</strong> Levantamiento de la uña</p>
      </div>
    </div>
    <div class="block">
      <span>
        1.¿Alguna vez ha tenido un brote en la piel compuesto por áreas
        escamosas de colores rojo y blanco, particularmente en los codos,
        rodillas o cuero cabelludo, como las mostradas en la
        <strong>figura 1</strong>?</span
      >

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.TOPAS_P1"
            :native-value="answers.TOPAS_P1.TOPAS_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.TOPAS_P1"
            :native-value="answers.TOPAS_P1.TOPAS_P1_R2"
            @input="saveTermporalSulrvey(), clearSubIput1()"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
      <div v-if="answerOne == 1">
        <div class="block mt-5">
          <span class="ml-5">
            a. ¿A qué edad notó por primera vez este brote en la piel?
          </span>
          <div class="columns mt-2">
            <div class="column is-offset-3 is-one-quarter">
              <b-field :max="120">
                <b-input
                  type="text"
                  maxlength="3"
                  v-model="answerOneA"
                  :disabled="queryMode"
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="block mt-5">
          <span class="ml-5">
            b. ¿Tiene este brote en la piel actualmente?
          </span>
          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                v-model="answerOneB"
                :name="questions.TOPAS_P1_B"
                :native-value="answers.TOPAS_P1_B.TOPAS_P1_B_R1"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                Si
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                v-model="answerOneB"
                :name="questions.TOPAS_P1_B"
                :native-value="answers.TOPAS_P1_B.TOPAS_P2_B_R2"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                No
              </b-radio>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        2. ¿Alguna vez ha notado alguno de estos cambios en sus uñas?
      </span>

      <div class="block mt-5">
        <span class="ml-5">
          a. Hoyuelos en las uñas, como los mostrados en la
          <strong>figura 2</strong>
        </span>
        <div class="columns mt-2">
          <div class="column"></div>
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="answerTwoA"
              :name="questions.TOPAS_P2_A"
              :native-value="answers.TOPAS_P2_A.TOPAS_P2_A_R1"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Si
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="answerTwoA"
              :name="questions.TOPAS_P2_A"
              :native-value="answers.TOPAS_P2_A.TOPAS_P2_A_R2"
              @input="saveTermporalSulrvey(), clearSubIput2()"
              :disabled="queryMode"
            >
              No
            </b-radio>
          </div>
        </div>
      </div>
      <div class="block">
        <span class="ml-5">
          b. Levantamientos en las uñas, como se muestra en la
          <strong>figura 3</strong>
        </span>
        <div class="columns mt-2">
          <div class="column"></div>
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="answerTwoB"
              :name="questions.TOPAS_P2_B"
              :native-value="answers.TOPAS_P2_B.TOPAS_P2_B_R1"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Si
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="answerTwoB"
              :name="questions.TOPAS_P2_B"
              :native-value="answers.TOPAS_P2_B.TOPAS_P2_B_R2"
              @input="saveTermporalSulrvey(), clearSubIput2()"
              :disabled="queryMode"
            >
              No
            </b-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="block" v-if="answerTwoA || answerTwoB">
      <span class="ml-5">
        Si marco si
      </span>
      <div class="block mt-5">
        <span class="ml-5">
          a. ¿A qué edad aproximada las notó por primera vez?
        </span>
        <div class="columns mt-2">
          <div class="column is-offset-3 is-one-quarter">
            <b-field>
              <b-input
                type="text"
                maxlength="3"
                v-model="answerTwoBA"
                :disabled="queryMode"
              >
              </b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="block">
        <span class="ml-5">
          b. ¿Tiene alguno de esos cambios en las uñas actualmente?
        </span>
        <div class="columns mt-2">
          <div class="column"></div>
          <div class="column has-text-right">
            <strong>Respuestas</strong>
          </div>
          <div class="column">
            <b-radio
              v-model="answerTwoBB"
              :name="questions.TOPAS_P2_B_B"
              :native-value="answers.TOPAS_P2_B_B.TOPAS_P2_B_B_R1"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              Si
            </b-radio>
          </div>
          <div class="column">
            <b-radio
              v-model="answerTwoBB"
              :name="questions.TOPAS_P2_B_B"
              :native-value="answers.TOPAS_P2_B_B.TOPAS_P2_B_B_R2"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
              No
            </b-radio>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        3. ¿Ha consultado a un médico alguna vez por un brote en la piel?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.TOPAS_P3"
            :native-value="answers.TOPAS_P3.TOPAS_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.TOPAS_P3"
            :native-value="answers.TOPAS_P3.TOPAS_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>4. ¿Algún médico le ha diagnosticado psoriasis alguna vez? </span>

      <div class="columns">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.TOPAS_P4"
            :native-value="answers.TOPAS_P4.TOPAS_P4_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.TOPAS_P4"
            :native-value="answers.TOPAS_P4.TOPAS_P4_R2"
            @input="saveTermporalSulrvey(), clearSubIput4()"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
      <div v-if="answerFour == 1">
        <div class="block mt-5">
          <span class="ml-5">
            a. ¿A qué edad notó por primera vez este brote en la piel?
          </span>
          <div class="columns mt-2">
            <div class="column is-offset-3 is-one-quarter">
              <b-field>
                <b-input
                  type="text"
                  maxlength="3"
                  v-model="answerFourA"
                  :disabled="queryMode"
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        5. ¿Ha tenido alguna vez dolor en las articulaciones, rigidez en las
        articulaciones, o enrojecimiento e inflamación de las articulaciones que
        no hayan sido causadas por un golpe?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.TOPAS_P5"
            :native-value="answers.TOPAS_P5.TOPAS_P5_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.TOPAS_P5"
            :native-value="answers.TOPAS_P5.TOPAS_P5_R2"
            @input="saveTermporalSulrvey(), clearSubIput5()"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
      <div v-if="answerFive == 1">
        <div class="block mt-5">
          <span class="ml-5">
            a. ¿A qué edad aproximada notó estos síntomas por primera vez?
          </span>
          <div class="columns mt-2">
            <div class="column is-offset-3 is-one-quarter">
              <b-field>
                <b-input
                  type="text"
                  maxlength="3"
                  v-model="answerFiveA"
                  :disabled="queryMode"
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="block mt-5">
          <span class="ml-5">
            b. ¿Tiene alguno de estos síntomas en este momento?
          </span>
          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                v-model="answerFiveB"
                :name="questions.TOPAS_P5_B"
                :native-value="answers.TOPAS_P5_B.TOPAS_P5_B_R1"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                Si
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                v-model="answerFiveB"
                :name="questions.TOPAS_P5_B"
                :native-value="answers.TOPAS_P5_B.TOPAS_P5_B_R2"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                No
              </b-radio>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        6. ¿Ha tenido alguna vez los dedos de las manos o de los pies hinchados
        en forma de salchicha, sin que esto haya sido causado por un golpe?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.TOPAS_P6"
            :native-value="answers.TOPAS_P6.TOPAS_P6_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.TOPAS_P6"
            :native-value="answers.TOPAS_P6.TOPAS_P6_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        7. ¿Ha tenido alguna vez dolor de cuello que durara por lo menos 3
        meses, que no estuviera relacionado con un golpe?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.TOPAS_P7"
            :native-value="answers.TOPAS_P7.TOPAS_P7_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.TOPAS_P7"
            :native-value="answers.TOPAS_P7.TOPAS_P7_R2"
            @input="saveTermporalSulrvey(), clearSubInput7()"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
      <div v-if="answerSeven == 1">
        <div class="block mt-5">
          <span class="ml-5">
            a. ¿El dolor en el cuello se acompañó de rigidez?
          </span>
          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                v-model="answerSevenA"
                :name="questions.TOPAS_P7_A"
                :native-value="answers.TOPAS_P7_A.TOPAS_P7_A_R1"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                Si
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                v-model="answerSevenA"
                :name="questions.TOPAS_P7_A"
                :native-value="answers.TOPAS_P7_A.TOPAS_P7_A_R2"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                No
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block mt-5">
          <span class="ml-5">
            b. ¿Tiene dolor de cuello actualmente?
          </span>
          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                v-model="answerSevenB"
                :name="questions.TOPAS_P7_B"
                :native-value="answers.TOPAS_P7_B.TOPAS_P7_B_R1"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                Si
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                v-model="answerSevenB"
                :name="questions.TOPAS_P7_B"
                :native-value="answers.TOPAS_P7_B.TOPAS_P7_B_R2"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                No
              </b-radio>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="block">
      <span>
        8. ¿Ha tenido alguna vez dolor de espalda que durara por lo menos 3
        meses, que no estuviera relacionado con un golpe?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.TOPAS_P8"
            :native-value="answers.TOPAS_P8.TOPAS_P8_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.TOPAS_P8"
            :native-value="answers.TOPAS_P8.TOPAS_P8_R2"
            @input="saveTermporalSulrvey(), clearSubInput8()"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
      <div v-if="answerEight == 1">
        <div class="block mt-5">
          <span class="ml-5">
            a. ¿El dolor de espalda estuvo acompañado por rigidez?
          </span>
          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                v-model="answerEightA"
                :name="questions.TOPAS_P8_A"
                :native-value="answers.TOPAS_P8_A.TOPAS_P8_A_R1"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                Si
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                v-model="answerEightA"
                :name="questions.TOPAS_P8_A"
                :native-value="answers.TOPAS_P8_A.TOPAS_P8_A_R2"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                No
              </b-radio>
            </div>
          </div>
        </div>
        <div class="block mt-5">
          <span class="ml-5">
            b. ¿Tiene dolor de espalda en este momento?
          </span>
          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                v-model="answerEightB"
                :name="questions.TOPAS_P8_B"
                :native-value="answers.TOPAS_P8_B.TOPAS_P8_B_R1"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                Si
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                v-model="answerEightB"
                :name="questions.TOPAS_P8_B"
                :native-value="answers.TOPAS_P8_B.TOPAS_P8_B_R2"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                No
              </b-radio>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="block">
      <span>
        9. ¿Ha tenido alguna vez un brote en la piel en cualquier parte de su
        cuerpo al mismo tiempo que dolor en las articulaciones, rigidez en las
        articulaciones o articulaciones inflamadas y rojas?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.TOPAS_P9"
            :native-value="answers.TOPAS_P9.TOPAS_P9_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.TOPAS_P9"
            :native-value="answers.TOPAS_P9.TOPAS_P9_R2"
            @input="saveTermporalSulrvey(), clearSubIput9()"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
      <div v-if="answerNine == 1">
        <div class="block mt-5">
          <span class="ml-5">
            a. ¿A qué edad aproximada notó estos síntomas por primera vez?
          </span>
          <div class="columns mt-2">
            <div class="column is-offset-3 is-one-quarter">
              <b-field>
                <b-input
                  type="text"
                  maxlength="3"
                  v-model="answerNineA"
                  :disabled="queryMode"
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="block mt-5">
          <span class="ml-5">
            b. ¿Tiene alguno de estos síntomas en este momento?
          </span>
          <div class="columns mt-2">
            <div class="column has-text-right">
              <strong>Respuestas</strong>
            </div>
            <div class="column">
              <b-radio
                v-model="answerNineB"
                :name="questions.TOPAS_P9_B"
                :native-value="answers.TOPAS_P9_B.TOPAS_P9_B_R1"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                Si
              </b-radio>
            </div>
            <div class="column">
              <b-radio
                v-model="answerNineB"
                :name="questions.TOPAS_P9_B"
                :native-value="answers.TOPAS_P9_B.TOPAS_P9_B_R2"
                @input="saveTermporalSulrvey"
                :disabled="queryMode"
              >
                No
              </b-radio>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="block">
      <span>
        10. ¿Alguna vez ha consultado a un médico por dolor en cualquier
        articulación?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.TOPAS_P10"
            :native-value="answers.TOPAS_P10.TOPAS_P10_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.TOPAS_P10"
            :native-value="answers.TOPAS_P10.TOPAS_P10_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        11. ¿Alguna vez le han diagnosticado algún tipo de artritis, diferente a
        la artritis psoriásica?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.TOPAS_P11"
            :native-value="answers.TOPAS_P11.TOPAS_P11_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEleven"
            :name="questions.TOPAS_P11"
            :native-value="answers.TOPAS_P11.TOPAS_P11_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
      <div class="block switchs-container" v-if="answerEleven == 1">
        <b-switch
          v-model="answerElevenA"
          class="my-1"
          :rounded="true"
          :outlined="true"
          type="is-info"
          :left-label="true"
          passive-type="is-dark"
          :disabled="queryMode"
          >Artritis reumatoide</b-switch
        >
        <b-switch
          v-model="answerElevenB"
          class="my-1"
          :rounded="true"
          :outlined="true"
          type="is-info"
          :left-label="true"
          passive-type="is-dark"
          :disabled="queryMode"
          >Osteoartritis</b-switch
        >

        <b-switch
          v-model="answerElevenC"
          class="my-1"
          :rounded="true"
          :outlined="true"
          type="is-info"
          :left-label="true"
          passive-type="is-dark"
          :disabled="queryMode"
          >Lupus (LES)</b-switch
        >

        <b-switch
          v-model="answerElevenD"
          class="my-1"
          :rounded="true"
          :outlined="true"
          type="is-info"
          :left-label="true"
          passive-type="is-dark"
          :disabled="queryMode"
          >Fibromialgia</b-switch
        >

        <b-switch
          v-model="answerElevenE"
          class="my-1"
          :rounded="true"
          :outlined="true"
          type="is-info"
          :left-label="true"
          passive-type="is-dark"
          :disabled="queryMode"
          >Espondilitis anquilosante</b-switch
        >

        <b-switch
          v-model="answerElevenF"
          class="my-1"
          :rounded="true"
          :outlined="true"
          type="is-info"
          :left-label="true"
          passive-type="is-dark"
          :disabled="queryMode"
          >Escleroderma</b-switch
        >

        <b-switch
          v-model="answerElevenG"
          class="my-1"
          :rounded="true"
          :outlined="true"
          type="is-info"
          :left-label="true"
          passive-type="is-dark"
          :disabled="queryMode"
          >Otro</b-switch
        >
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        12. ¿Alguna vez un médico le ha diagnosticado artritis psoriásica?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.TOPAS_P12"
            :native-value="answers.TOPAS_P12.TOPAS_P12_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Si
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwelve"
            :name="questions.TOPAS_P12"
            :native-value="answers.TOPAS_P12.TOPAS_P12_R2"
            @input="saveTermporalSulrvey(), clearSubIput12()"
            :disabled="queryMode"
          >
            No
          </b-radio>
        </div>
      </div>
      <div v-if="answerTwelve">
        <div class="block mt-5">
          <span class="ml-5">
            a. ¿A qué edad le hicieron el diagnóstico por primera vez?
          </span>
          <div class="columns mt-2">
            <div class="column is-offset-3 is-one-quarter">
              <b-field>
                <b-input
                  type="text"
                  maxlength="3"
                  v-model="answerTwelveA"
                  :disabled="queryMode"
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block has-text-centered title">
      Resultado : {{ score }} - {{ scoreText }}
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>
    <!-- <div class="block has-text-centered title">
      Descripcion : {{ scoreText }}
    </div> -->

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { TOPAS as questions } from "../../../data/questions";
import { TOPAS as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: "",
      answerOneA: "",
      answerOneB: "",
      answerTwoBA: "",
      answerTwoBB: "",
      answerTwoA: "",
      answerTwoB: "",
      answerThree: "",
      answerFour: "",
      answerFourA: "",
      answerFive: "",
      answerFiveA: "",
      answerFiveB: "",
      answerSix: "",
      answerSeven: "",
      answerSevenA: "",
      answerSevenB: "",
      answerEight: "",
      answerEightA: "",
      answerEightB: "",
      answerNine: "",
      answerNineA: "",
      answerNineB: "",
      answerTen: "",
      answerEleven: "",
      answerElevenA: false,
      answerElevenB: false,
      answerElevenC: false,
      answerElevenD: false,
      answerElevenE: false,
      answerElevenF: false,
      answerElevenG: false,
      answerTwelve: "",
      answerTwelveA: "",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "TopasList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerOneA = this.$route.params.temporalData.answerOneA;
      this.answerOneB = this.$route.params.temporalData.answerOneB;
      this.answerTwoA = this.$route.params.temporalData.answerTwoA;
      this.answerTwoB = this.$route.params.temporalData.answerTwoB;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
      this.answerFourA = this.$route.params.temporalData.answerFourA;
      this.answerFive = this.$route.params.temporalData.answerFive;
      this.answerFiveA = this.$route.params.temporalData.answerFiveA;
      this.answerFiveB = this.$route.params.temporalData.answerFiveB;
      this.answerSix = this.$route.params.temporalData.answerSix;
      this.answerSeven = this.$route.params.temporalData.answerSeven;
      this.answerSevenA = this.$route.params.temporalData.answerSevenA;
      this.answerSevenB = this.$route.params.temporalData.answerSevenB;
      this.answerEight = this.$route.params.temporalData.answerEight;
      this.answerEightA = this.$route.params.temporalData.answerEightA;
      this.answerEightB = this.$route.params.temporalData.answerEightB;
      this.answerNine = this.$route.params.temporalData.answerNine;
      this.answerFiveA = this.$route.params.temporalData.answerFiveA;
      this.answerNineB = this.$route.params.temporalData.answerNineB;
      this.answerTen = this.$route.params.temporalData.answerTen;
      this.answerEleven = this.$route.params.temporalData.answerEleven;
      this.answerTwelve = this.$route.params.temporalData.answerTwelve;
      this.answerTwelveA = this.$route.params.temporalData.answerTwelveA;
    }

    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      let ans2 = "";
      if (this.answerTwoA == 1 || this.answerTwoB == 1) {
        ans2 = 1;
      } else {
        ans2 = 0;
      }

      return (
        parseInt(this.answerOne || 0) +
          parseInt(ans2 || 0) +
          parseInt(this.answerThree || 0) +
          parseInt(this.answerFour || 0) +
          parseInt(this.answerFive || 0) +
          parseInt(this.answerSix || 0) +
          parseInt(this.answerSeven || 0) +
          parseInt(this.answerEight || 0) +
          parseInt(this.answerNine || 0) +
          parseInt(this.answerTen || 0) +
          parseInt(this.answerEleven || 0) +
          parseInt(this.answerTwelve || 0) || 0
      );
    },
    scoreText() {
      let text = "NO Sospecha de artritis psoriásica";

      if (this.score >= 8) {
        text = "Sospecha de artritis psoriásica";
      }

      return text;
    },
    isIncompleted() {
      return (
        this.answerOne === "" ||
        (this.answerOne == 1 &&
          (this.answerOneA === "" || this.answerOneB === "")) ||
        this.answerTwoA === "" ||
        this.answerTwoB === "" ||
        ((this.answerTwoA == 1 || this.answerTwoB == 1) &&
          (this.answerTwoBA === "" || this.answerTwoBB === "")) ||
        this.answerThree === "" ||
        this.answerFour === "" ||
        (this.answerFour == 1 && this.answerFourA === "") ||
        this.answerFive === "" ||
        (this.answerFive == 1 &&
          (this.answerFiveA === "" || this.answerFiveB === "")) ||
        this.answerSix === "" ||
        this.answerSeven === "" ||
        (this.answerSeven == 1 &&
          (this.answerSevenA === "" || this.answerSevenB === "")) ||
        this.answerEight === "" ||
        (this.answerEight == 1 &&
          (this.answerEightA === "" || this.answerEightB === "")) ||
        this.answerNine === "" ||
        (this.answerNine == 1 &&
          (this.answerNineA === "" || this.answerNineB === "")) ||
        this.answerTen === "" ||
        this.answerEleven === "" ||
        this.answerTwelve === "" ||
        (this.answerTwelve == 1 && this.answerTwelveA === "")
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = "";
      this.answerOneA = "";
      this.answerOneB = "";
      this.answerOneB = "";
      this.answerTwoA = "";
      this.answerTwoB = "";
      this.answerTwoBA = "";
      this.answerTwoBB = "";
      this.answerThree = "";
      this.answerFour = "";
      this.answerFourA = "";
      this.answerFive = "";
      this.answerFiveA = "";
      this.answerFiveB = "";
      this.answerSix = "";
      this.answerSeven = "";
      this.answerSevenA = "";
      this.answerSevenB = "";
      this.answerEight = "";
      this.answerEightA = "";
      this.answerEightB = "";
      this.answerNine = "";
      this.answerNineA = "";
      this.answerNineB = "";
      this.answerTen = "";
      this.answerEleven = "";
      this.answerTwelve = "";
      this.answerTwelveA = "";
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerOneA = this.answerOneA;
      this.restoreData.answerOneB = this.answerOneB;
      this.restoreData.answerTwoA = this.answerTwoA;
      this.restoreData.answerTwoB = this.answerTwoB;
      this.restoreData.answerTwoBA = this.answerTwoBA;
      this.restoreData.answerTwoBB = this.answerTwoBB;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;
      this.restoreData.answerFourA = this.answerFourA;
      this.restoreData.answerFive = this.answerFive;
      this.restoreData.answerFiveA = this.answerFiveA;
      this.restoreData.answerFiveB = this.answerFiveB;
      this.restoreData.answerSix = this.answerSix;
      this.restoreData.answerSeven = this.answerSeven;
      this.restoreData.answerSevenA = this.answerSevenA;
      this.restoreData.answerSevenB = this.answerSevenB;
      this.restoreData.answerEight = this.answerEight;
      this.restoreData.answerEightA = this.answerEightA;
      this.restoreData.answerEightB = this.answerEightB;
      this.restoreData.answerNine = this.answerNine;
      this.restoreData.answerNineA = this.answerNineA;
      this.restoreData.answerNineB = this.answerNineB;
      this.restoreData.answerTen = this.answerTen;
      this.restoreData.answerEleven = this.answerEleven;
      this.restoreData.answerTwelve = this.answerTwelve;
      this.restoreData.answerTwelveA = this.answerTwelveA;

      this.initialAnswer();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta TOPAS.");
    },
    async save() {
      try {
        this.setAnswers();
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "TOPAS",
          result: String(this.score),
          appreciation: String(this.scoreText),
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question1b = { questionCode: questions.TOPAS_P1_B, value: "" };
      let question2a = {};
      let question2b = null;
      let question2bb = { questionCode: questions.TOPAS_P2_B_B, value: "" };
      let question3 = null;
      let question4 = null;
      let question5 = null;
      let question5b = { questionCode: questions.TOPAS_P5_B };
      let question6 = null;
      let question7 = null;
      let question7a = { questionCode: questions.TOPAS_P7_A };
      let question7b = { questionCode: questions.TOPAS_P7_B };
      let question8 = null;
      let question8a = { questionCode: questions.TOPAS_P8_A };
      let question8b = { questionCode: questions.TOPAS_P8_B };
      let question9 = { questionCode: questions.TOPAS_P1_B };
      let question9b = { questionCode: questions.TOPAS_P1_B };
      let question10 = null;
      let question11 = null;
      let question12 = null;

      Object.keys(this.answers[questions.TOPAS_P1]).forEach((key) => {
        if (this.answers[questions.TOPAS_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.TOPAS_P1,
            answerCode: key,
            value: this.answerOne == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P1_B]).forEach((key) => {
        if (this.answers[questions.TOPAS_P1_B][key] == this.answerOneB) {
          question1b = {
            questionCode: questions.TOPAS_P1_B,
            answerCode: key,
            value: this.answerOneB == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P2_A]).forEach((key) => {
        if (this.answers[questions.TOPAS_P2_A][key] == this.answerTwoA) {
          question2a = {
            questionCode: questions.TOPAS_P2_A,
            answerCode: key,
            value: this.answerTwoA == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P2_B]).forEach((key) => {
        if (this.answers[questions.TOPAS_P2_B][key] == this.answerTwoB) {
          question2b = {
            questionCode: questions.TOPAS_P2_B,
            answerCode: key,
            value: this.answerTwoB == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P2_B_B]).forEach((key) => {
        if (this.answers[questions.TOPAS_P2_B_B][key] == this.answerTwoBB) {
          question2bb = {
            questionCode: questions.TOPAS_P2_B_B,
            answerCode: key,
            value: this.answerTwoBB == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P3]).forEach((key) => {
        if (this.answers[questions.TOPAS_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.TOPAS_P3,
            answerCode: key,
            value: this.answerThree == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P4]).forEach((key) => {
        if (this.answers[questions.TOPAS_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.TOPAS_P4,
            answerCode: key,
            value: this.answerFour == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P5]).forEach((key) => {
        if (this.answers[questions.TOPAS_P5][key] == this.answerFive) {
          question5 = {
            questionCode: questions.TOPAS_P5,
            answerCode: key,
            value: this.answerFive == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P5_B]).forEach((key) => {
        if (this.answers[questions.TOPAS_P5_B][key] == this.answerFiveB) {
          question5b = {
            questionCode: questions.TOPAS_P5_B,
            answerCode: key,
            value: this.answerFiveB == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P6]).forEach((key) => {
        if (this.answers[questions.TOPAS_P6][key] == this.answerSix) {
          question6 = {
            questionCode: questions.TOPAS_P6,
            answerCode: key,
            value: this.answerSix == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P7]).forEach((key) => {
        if (this.answers[questions.TOPAS_P7][key] == this.answerSeven) {
          question7 = {
            questionCode: questions.TOPAS_P7,
            answerCode: key,
            value: this.answerSeven == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P7_A]).forEach((key) => {
        if (this.answers[questions.TOPAS_P7_A][key] == this.answerSevenA) {
          question7a = {
            questionCode: questions.TOPAS_P7_A,
            answerCode: key,
            value: this.answerSevenA == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P7_B]).forEach((key) => {
        if (this.answers[questions.TOPAS_P7_B][key] == this.answerSevenB) {
          question7b = {
            questionCode: questions.TOPAS_P7_B,
            answerCode: key,
            value: this.answerSevenB == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P8]).forEach((key) => {
        if (this.answers[questions.TOPAS_P8][key] == this.answerEight) {
          question8 = {
            questionCode: questions.TOPAS_P8,
            answerCode: key,
            value: this.answerEight == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P8_A]).forEach((key) => {
        if (this.answers[questions.TOPAS_P8_A][key] == this.answerEightA) {
          question8a = {
            questionCode: questions.TOPAS_P8_A,
            answerCode: key,
            value: this.answerEightA == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P8_B]).forEach((key) => {
        if (this.answers[questions.TOPAS_P8_B][key] == this.answerEightB) {
          question8b = {
            questionCode: questions.TOPAS_P8_B,
            answerCode: key,
            value: this.answerEightB == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P9]).forEach((key) => {
        if (this.answers[questions.TOPAS_P9][key] == this.answerNine) {
          question9 = {
            questionCode: questions.TOPAS_P9,
            answerCode: key,
            value: this.answerNine == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P9_B]).forEach((key) => {
        if (this.answers[questions.TOPAS_P9_B][key] == this.answerNineB) {
          question9b = {
            questionCode: questions.TOPAS_P9_B,
            answerCode: key,
            value: this.answerNineB == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P10]).forEach((key) => {
        if (this.answers[questions.TOPAS_P10][key] == this.answerTen) {
          question10 = {
            questionCode: questions.TOPAS_P10,
            answerCode: key,
            value: this.answerTen == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P11]).forEach((key) => {
        if (this.answers[questions.TOPAS_P11][key] == this.answerEleven) {
          question11 = {
            questionCode: questions.TOPAS_P11,
            answerCode: key,
            value: this.answerEleven == true ? "Si" : "No",
          };
        }
      });

      Object.keys(this.answers[questions.TOPAS_P12]).forEach((key) => {
        if (this.answers[questions.TOPAS_P12][key] == this.answerTwelve) {
          question12 = {
            questionCode: questions.TOPAS_P12,
            answerCode: key,
            value: this.answerTwelve == true ? "Si" : "No",
          };
        }
      });

      let question1a = {
        questionCode: questions.TOPAS_P1_A,
        answerCode: null,
        value: this.answerOneA,
      };

      let question2ba = {
        questionCode: questions.TOPAS_P2_B_A,
        answerCode: null,
        value: this.answerTwoBA,
      };

      let question4a = {
        questionCode: questions.TOPAS_P4_A,
        answerCode: null,
        value: this.answerFourA,
      };

      let question5a = {
        questionCode: questions.TOPAS_P5_A,
        answerCode: null,
        value: this.answerFiveA,
      };

      let question9a = {
        questionCode: questions.TOPAS_P9_A,
        answerCode: null,
        value: this.answerNineA,
      };

      let question11A = {
        questionCode: questions.TOPAS_P11A,
        answerCode: null,
        value: this.answerElevenA == true ? "Si" : "No",
      };

      let question11B = {
        questionCode: questions.TOPAS_P11B,
        answerCode: null,
        value: this.answerElevenB == true ? "Si" : "No",
      };

      let question11C = {
        questionCode: questions.TOPAS_P11C,
        answerCode: null,
        value: this.answerElevenC == true ? "Si" : "No",
      };

      let question11D = {
        questionCode: questions.TOPAS_P11D,
        answerCode: null,
        value: this.answerElevenD == true ? "Si" : "No",
      };

      let question11E = {
        questionCode: questions.TOPAS_P11E,
        answerCode: null,
        value: this.answerElevenE == true ? "Si" : "No",
      };

      let question11F = {
        questionCode: questions.TOPAS_P11F,
        answerCode: null,
        value: this.answerElevenF == true ? "Si" : "No",
      };

      let question11G = {
        questionCode: questions.TOPAS_P11G,
        answerCode: null,
        value: this.answerElevenG == true ? "Si" : "No",
      };

      let question12a = {
        questionCode: questions.TOPAS_P12_A,
        answerCode: null,
        value: this.answerTwelveA,
      };
      let answersReturn = [];
      let answerConsolidate = [
        question1,
        question1a,
        question1b,
        question2a,
        question2b,
        question2ba,
        question2bb,
        question3,
        question4,
        question4a,
        question5,
        question5a,
        question5b,
        question6,
        question7,
        question7a,
        question7b,
        question8,
        question8a,
        question8b,
        question9,
        question9a,
        question9b,
        question10,
        question11,
        question11A,
        question11B,
        question11C,
        question11D,
        question11E,
        question11F,
        question11G,
        question12,
        question12a,
      ];

      answerConsolidate.forEach((object) => {
        if (String(object.value).trim() != "") {
          answersReturn.push(object);
        }
      });

      return answersReturn;
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerOneA = this.restoreData.answerOneA;
      this.answerOneB = this.restoreData.answerOneB;
      this.answerTwoA = this.restoreData.answerTwoA;
      this.answerTwoB = this.restoreData.answerTwoB;
      this.answerTwoBA = this.restoreData.answerTwoBA;
      this.answerTwoBB = this.restoreData.answerTwoBB;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;
      this.answerFourA = this.restoreData.answerFourA;
      this.answerFive = this.restoreData.answerFive;
      this.answerFiveA = this.restoreData.answerFiveA;
      this.answerSix = this.restoreData.answerSix;
      this.answerSeven = this.restoreData.answerSeven;
      this.answerSevenA = this.restoreData.answerSevenA;
      this.answerSevenB = this.restoreData.answerSevenB;
      this.answerEight = this.restoreData.answerEight;
      this.answerEightA = this.restoreData.answerEightA;
      this.answerEightB = this.restoreData.answerEightB;
      this.answerNine = this.restoreData.answerNine;
      this.answerNineA = this.restoreData.answerNineA;
      this.answerTen = this.restoreData.answerTen;
      this.answerEleven = this.restoreData.answerEleven;
      this.answerTwelve = this.restoreData.answerTwelve;
      this.answerTwelveA = this.restoreData.answerTwelveA;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "TOPAS",
          route: "Topas",
        },
        survey: {
          answerOne: this.answerOne,
          answerOneB: this.answerOneB,
          answerTwoA: this.answerTwoA,
          answerTwoB: this.answerTwoB,
          answerTwoBA: this.answerTwoBA,
          answerTwoBB: this.answerTwoBB,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
          answerFive: this.answerFive,
          answerFiveB: this.answerFiveB,
          answerSix: this.answerSix,
          answerSeven: this.answerSeven,
          answerSevenA: this.answerSevenA,
          answerSevenB: this.answerSevenB,
          answerEight: this.answerEight,
          answerEightA: this.answerEightA,
          answerEightB: this.answerEightB,
          answerNine: this.answerNine,
          answerNineB: this.answerNineB,
          answerTen: this.answerTen,
          answerEleven: this.answerEleven,
          answerTwelve: this.answerTwelve,
        },
      });
    },
    clearSubIput1() {
      this.answerOneA = "";
      this.answerOneB = "";
    },
    clearSubIput2() {
      if (this.answerTwoA == 0 && this.answerTwoB == 0) {
        this.answerTwoBA = "";
        this.answerTwoBB = "";
      }
    },
    clearSubIput4() {
      this.answerFourA = "";
    },
    clearSubIput5() {
      this.answerFiveA = "";
      this.answerFiveB = "";
    },
    clearSubInput7() {
      this.answerSevenA = "";
      this.answerSevenB = "";
    },
    clearSubInput8() {
      this.answerEightA = "";
      this.answerEightB = "";
    },
    clearSubIput9() {
      this.answerNineA = "";
      this.answerNineB = "";
    },
    clearSubIput12() {
      this.answerTwelveA = "";
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "TOPAS_P1") {
          this.answerOne = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question?.code == "TOPAS_P1_A") {
          this.answerOneA = survey.value;
        }

        if (survey.question?.code == "TOPAS_P1_B") {
          this.answerOneB = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P2_A") {
          this.answerTwoA = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P2_B") {
          this.answerTwoB = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P2_B_A") {
          this.answerTwoBA = survey.value;
        }

        if (survey.question.code == "TOPAS_P2_B_B") {
          this.answerTwoBB =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P3") {
          this.answerThree =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P4") {
          this.answerFour = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P4_A") {
          this.answerFourA = survey.value;
        }

        if (survey.question.code == "TOPAS_P5") {
          this.answerFive = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P5_A") {
          this.answerFiveA = survey.value;
        }

        if (survey.question.code == "TOPAS_P5_B") {
          this.answerFiveB =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P6") {
          this.answerSix = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P7") {
          this.answerSeven =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P7_A") {
          this.answerSevenA =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P7_B") {
          this.answerSevenB =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P8") {
          this.answerEight =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P8_A") {
          this.answerEightA = survey.value;
        }

        if (survey.question.code == "TOPAS_P8_B") {
          this.answerEightB = survey.value;
        }

        if (survey.question.code == "TOPAS_P9") {
          this.answerNine = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P9_A") {
          this.answerNineA = survey.value;
        }

        if (survey.question.code == "TOPAS_P9_B") {
          this.answerNineB = survey.value;
        }

        if (survey.question.code == "TOPAS_P9_B") {
          this.answerNineB = survey.value;
        }

        if (survey.question.code == "TOPAS_P10") {
          this.answerTen = answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question.code == "TOPAS_P11") {
          this.answerEleven =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question?.code == "TOPAS_P11_ARTRITIS") {
          this.answerElevenA = survey.value == "Si" ? true : false;
        }

        if (survey.question?.code == "TOPAS_P11_ESCLERODERMA") {
          this.answerElevenB = survey.value == "Si" ? true : false;
        }

        if (survey.question?.code == "TOPAS_P11_ESPONDILITIS") {
          this.answerElevenC = survey.value == "Si" ? true : false;
        }

        if (survey.question?.code == "TOPAS_P11_FIBROMIAGLIA") {
          this.answerElevenD = survey.value == "Si" ? true : false;
        }

        if (survey.question?.code == "TOPAS_P11_LUPUS") {
          this.answerElevenE = survey.value == "Si" ? true : false;
        }

        if (survey.question?.code == "TOPAS_P11_OSTEOARTRITIS") {
          this.answerElevenF = survey.value == "Si" ? true : false;
        }

        if (survey.question?.code == "TOPAS_P11_OTRO") {
          this.answerElevenG = survey.value == "Si" ? true : false;
        }

        if (survey.question?.code == "TOPAS_P12") {
          this.answerTwelve =
            answers[survey.question?.code][survey.answer?.code];
        }

        if (survey.question?.code == "TOPAS_P12_A") {
          this.answerTwelveA = survey.value;
        }

        if (survey.question?.code == "TOPAS_P12_B") {
          this.answerTwelveB = survey.value;
        }
      });
    },
    initialAnswer() {
      this.answerOne = this.answers.TOPAS_P1.TOPAS_P1_R2;
      this.answerOneB = this.answers.TOPAS_P1_B.TOPAS_P1_B_R2;
      this.answerTwoA = this.answers.TOPAS_P2_A.TOPAS_P2_A_R2;
      this.answerTwoB = this.answers.TOPAS_P2_B.TOPAS_P2_B_R2;
      this.answerTwoBA = this.answers.TOPAS_P1.TOPAS_P1_R2;
      this.answerTwoBB = this.answers.TOPAS_P2_B_B.TOPAS_P2_B_B_R2;
      this.answerThree = this.answers.TOPAS_P3.TOPAS_P3_R2;
      this.answerFour = this.answers.TOPAS_P4.TOPAS_P4_R2;
      this.answerFive = this.answers.TOPAS_P5.TOPAS_P5_R2;
      this.answerFiveB = this.answers.TOPAS_P5_B.TOPAS_P5_B_R2;
      this.answerSix = this.answers.TOPAS_P6.TOPAS_P6_R2;
      this.answerSeven = this.answers.TOPAS_P7.TOPAS_P7_R2;
      this.answerSevenA = this.answers.TOPAS_P7_A.TOPAS_P7_A_R2;
      this.answerSevenB = this.answers.TOPAS_P7_B.TOPAS_P7_B_R2;
      this.answerEight = this.answers.TOPAS_P8.TOPAS_P8_R2;
      this.answerEightA = this.answers.TOPAS_P8_A.TOPAS_P8_A_R2;
      this.answerEightB = this.answers.TOPAS_P8_B.TOPAS_P8_B_R2;
      this.answerNine = this.answers.TOPAS_P9.TOPAS_P9_R2;
      this.answerNineB = this.answers.TOPAS_P9_B.TOPAS_P9_B_R2;
      this.answerTen = this.answers.TOPAS_P10.TOPAS_P10_R2;
      this.answerEleven = this.answers.TOPAS_P11.TOPAS_P11_R2;
      this.answerTwelve = this.answers.TOPAS_P12.TOPAS_P12_R2;
    },
  },
};
</script>

<style scoped>
.helper-img {
  width: 100%;
  height: 70%;
}

.switchs-container {
  width: 100vh;
  display: grid;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .switchs-container {
    width: 38vh;
  }
}
</style>
